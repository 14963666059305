import React, { useEffect, useState, useRef } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { connectApp } from "./store/app.connect";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import OneSignal from "react-onesignal";
import Cookies from "js-cookie";
import {
  ID_TOKEN,
  USER_DATA,
  TOKEN_EXP,
  GET_BALANCE_INTERVAL,
  GET_MENU_INTERVAL,
  GET_MESSAGE_INTERVAL,
  GET_BALANCE_SETTINGS_INTERVAL,
  KEEP_ALIVE_POPUP,
  goBackPages
} from "./config/app.config";
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  useIonViewWillEnter,
  withIonLifeCycle
} from "@ionic/react";
import { createMemoryHistory } from "history";
import { IonReactMemoryRouter } from "@ionic/react-router";
import { menuController } from "@ionic/core";

import Login from "./pages/Login/Login";
import DefaultPage from "./pages/DefaultPage/DefaultPage";
import MyMarkets from "./pages/MyMarkets/MyMarkets";
import Search from "./pages/Search/Search";
import SessionPopover from "./components/SessionPopover/SessionPopover";
import BetPlacingPopover from "./components/BetPlacingPopover/BetPlacingPopover";
import LeftSideMenu from "./components/LeftSideMenu/LeftSideMenu";
import RightSideMenu from "./components/RightSideMenu/RightSideMenu";
import RulesRegs from "./pages/RulesRegs/RulesRegs";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import TimeSettings from "./pages/TimeSettings/TimeSettings";
import Settings from "./pages/Settings/Settings";
import ReduxToastr, {toastr} from "react-redux-toastr";
import MenuEvent from "./pages/MenuEvent";
import Competition from "./pages/Competitions";
import ResponseGambling from "./pages/ResponsibleGambling";
import ExclusionPolicy from "./pages/ExclusionPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MyMarketsFab from "./components/MyMarketsFab/MyMarketsFab";
import BalanceInformation from "./components/BalanceInformation/BalanceInformation";
import BetSettingsDesktop from "./components/BetSettingsDesktop/BetSettingsDesktop";
import BetSlipDesktop from "./components/BetSlipDesktop/BetSlipDesktop";
import { BetPopoverContext } from "./context";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./App.scss";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./_colorScheme.scss";
import BettingProfitAndLosePage from "./pages/BettingProfitAndLossPage/BettingProfitAndLossPage";
import BettingProfitAndLossDetailsPage from "./pages/BettingProfitAndLoseDetailsPage/BettingProfitAndLossDetailsPage";
import TransferStatementPage from "./pages/TransferStatementPage/TransferStatementPage";
import EventPage from "./pages/EventPage/EventPage";
import KycPage from "./pages/KycPage/KycPage";
import MyOpenBets from "./pages/MyOpenBets/MyOpenBets";
import RulesPage from "./pages/RulesPage/RulesPage";
import CasinoGames from "./pages/CasinoGames/CasinoGames";
import CasinoLive from "./pages/CasinoLive/CasinoLive";
import LiveCasinoAndSlotPage from "./pages/LiveCasino&SlotPage/LiveCasino&SlotPage";
import Sportbook from "./pages/Sportbook/Sportbook";
import Scorecard from "./pages/Scorecard/Scorecard";
import AccountStatementPage from "./pages/AccountStatementPage/AccountStatementPage";
import TransactionPopup from "./components/TransactionPopup/TransactionPopup";
import AccountStatementDetailsPage from "./pages/AccountStatementDetailsPage/AccountStatementDetailsPage";
import * as Sentry from "@sentry/react";
import Deposit from "./pages/Deposit/Deposit";
import Transactions from "./pages/Transactions/Transactions";
import DetailedDepositTransaction from "./pages/DetailedDepositTransaction/DetailedDepositTransaction";
import DepositTimer from "./pages/DepositTimer/DepositTimer";
import Withdrawal from "./pages/Withdrawal/Withdrawal";
import ThanksWithdrawal from "./pages/ThanksWithdrawal/ThanksWithdrawal";
import TermsOfWithdrowalPayment from "./pages/TermsOfWithdrowalPayment/TermsOfWithdrowalPayment";
import ToolbarDesktop from "./components/ToolbarDesktop/ToolbarDesktop";
import FavouritesPage from "./pages/FavouritesPage/FavouritesPage";
import SearchDesktop from "./pages/SearchDesktop/SearchDesktop";
import EditStakesPopUp from "./components/BetSlipDesktop/EditStakesPopUp/EditStakesPopUp";
import { useDispatch, useSelector } from "react-redux";
import LeftSideMenuDesktop from "./components/LeftSideMenuDesktop/LeftSideMenuDesktop";
import Toolbar from "./components/Toolbar/Toolbar";
import SportsbookNotAuth from "./pages/SportbookNotAuth/SportbookNotAuth";

import ConfirmBetPopUp from "./components/DesktopBetPlacing/ConfirmBetPopup/ConfirmBetPopUp";
import SportsPage from "./pages/SportsPage/SportsPage";
import DesktopLogin from "./pages/DesktopLogin/DesktopLogin";
import FooterDesktop from "./components/FooterDesktop/FooterDesktop";
import RacingPage from "./pages/RacingPage/RacingPage";
import ExchangeGames from "./pages/ExchangeGames/ExchangeGames";
import ExchangeGamesMobile from "./pages/ExchangeGamesMobile/ExchangeGamesMobile";
import ExchangeGamePage from "./pages/ExchangeGamePage/ExchangeGamePage";
import LiveCasinoLobby from "./pages/LiveCasinoLobby/LiveCasinoLobby";
import LoginPopUp from "./components/LoginPopUp";
import RuleRegulationPopup from './components/RuleRegulationPopup';
import BonusPopUp from "./components/BonusPopUp";
import SlotsGamesLobby from "./pages/SlotsGamesLobby/SlotsGamesLobby";
import { BASE_DEV_URL, CONFIG, hosts } from "./config/api.config";
import { setReq } from "./store/deposit/deposit.slice";
import BonusStatementPage from "./pages/BonusStatementPage/BonusStatementPage";
import axios from "axios";
import LastResults from "./components/ExchangeGame/helpers/LastResults/LastResults";
import RulesPopUp from "./components/ExchangeGame/helpers/Rules/RulesPopUp";
import UpdatePopUp from "./components/UpdatePopUp";
import whatsAppImg from "../src/assets/images/whatsApp.svg";
import telegramIcon from "../src/assets/images/socials/telegram_icon.svg";
import { createPortal } from "react-dom";
import DepositTimerQr from "./pages/DepositTimerQr/DepositTimerQr";
import ErrorPopUp from "./components/ErrorHandler/ErrorPopUp";
import Bonuses from './pages/bonuses/Bonuses'
import ReactGA from "react-ga4";
import Profile from "./pages/Profile/Profile";
import { Socket } from "./services/socket";
import VirtualEventPage from "./pages/VirtualEventPage/VirtualEventPage";
import ComingSoonPopup from "./components/comingSoonPopup/ComingSoon";
import CasinoLiveNew from "./pages/CasinoLive/CasinoLiveNew";
const modalRoot = document.querySelector("#modal-root");


const presetPage =
  localStorage.getItem("page") && localStorage.getItem("pageStack");
// const presetPageStack = presetPage ? JSON.parse(localStorage.getItem('pageStack')) : ['/']
const presetPageStack = presetPage
  ? JSON.parse(localStorage.getItem("pageStack"))
  : ["/default-page"];
const history = createMemoryHistory({
  initialEntries: presetPageStack,
  initialIndex: presetPageStack.length - 1
});

const App = ({
               actions,
               auth,
               authData,
               user,
               stakes,
               balance,
               balanceSettings,
               menu,
               message,
               continueSessionModalShow,
               showBetPlacingPopover,
               lastActivityDate,
               loginNew,
               balanceInformationHeight,
               isPopUpOpenDispatch,
               isConfirmBetPopUpOpen,
               rulesPopUp,
              //  ruleRegulationPopup,
               elementsParams,
               version,
               versionPopUp,
               state
             }) => {
  const [timeCounter, setTimeCounter] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const appRef = useRef(null);
  const refBetPopover = useRef(null);
  const refSessionPopover = useRef(null);
  const bonusPopUp = useSelector((state) => state.bonusPopUp.isPopUpOpen);
  const ruleRegulationPopUp = useSelector((state) => state.ruleRegulationPopup);
  const dispatch = useDispatch();
  const [animApp, setAnimApp] = useState(false);
  const [animAppOpen, setAnimAppOpen] = useState(false);
  const [animAppClose, setAnimAppClose] = useState(false);


  //Needed for Ionic re-renderer (betbyWidget and Sportsbook)
  const [localPathnameNow, setLocalPathnameNow] = useState();

  // localStorage.setItem('waId', window.location.href.split('=')[1])

  // useEffect(() => {
  //   if(window.location.href?.includes('waId')){
  //     localStorage.setItem('waId', window.location.href.split('=')[1])
  //     actions.whatsAppReq(localStorage.getItem('waId'));
  //   }
  //   if (window.location.href?.includes("verify")) {
  //     const memberCode = window.location.href?.split("email/")?.[1]?.split("/")?.[0];
  //     const verifyToken = window.location.href?.split("email/")?.[1]?.split("/")?.[1];
  //     console.log('START VERIFY IN HREF',memberCode ,verifyToken);
  //     actions.confirmMailVerification(memberCode, verifyToken)
  //   }
  // }, [window.location.href])

  useEffect(() => {
    if(stakes?.length <= 0) {
      actions.requestStakeButtons()
    }
  }, []);


  useEffect(() => {
    Socket.on('logout', handleLogOut);

    return() => {
      Socket.off('logout', handleLogOut);
    }
  }, [])


  useEffect(() => {
    if(auth) {
      actions.requestBetDelay();
    }
  }, [auth]);

  useEffect(() => {
    Socket.on('place-bet-success', placeBetSuccess);
    Socket.on('place-bet-error', placeBetError);
    if(!history.location.pathname.includes('my-open-bets')) {
      Socket.on(`get-bets-success`, getBetsSuccess)
    }
    return () => {
      Socket.off('place-bet-success', placeBetSuccess);
      Socket.off('place-bet-error', placeBetError);
      if(!history.location.pathname.includes('my-open-bets')) {
        Socket.off(`get-bets-success`, getBetsSuccess)
      }
      actions.getAllEventsOpenBetsError();
      actions.getEventPageOpenBetsError();
    }
  }, [history.location]);
  
  useEffect(() => {
    requestBets();
  }, [history.location, user])

  const placeBetSuccess = (...args) => {
    toastr.success('', args[0]?.message);
    clearBetPlaceData();
    requestBets();
  }
  const placeBetError = (...args) => {
    toastr.error('', args[0]?.message);
    clearBetPlaceData();
  }
  const getBetsSuccess = (...args) => {
    if(!Array.isArray(args[0])) return;

    let eventId = null;
    // eventpages ke liye eventId nikaal lo
    if(history.location.pathname.includes('virtual-event-page')){
      const parts = history?.location?.pathname.split('/');
      const eventTypeId = parts[parts.length - 1];
      eventId = eventTypeId == 'v9'? '1234822733' : '1122334455';
    }else if(history.location.pathname.includes('event-page')) {
      const parts = history?.location?.pathname.split('/');
      eventId = parts[parts.length - 1];
    }

    actions.getAllEventsOpenBetsSuccess(args[0]);
    // favouritepage ke liye eventopenbets aur alleventopenbets same hongi, kyuki sportmarketstandard.js use ho rahi hai..
    if(history.location.pathname.includes('favouritesPage')) {
      actions.getEventPageOpenBetsSuccess(args[0]);
    }else if (eventId) {
      actions.getEventPageOpenBetsSuccess(args[0].filter(bet => bet.eventId === eventId));
    } else {
      actions.getEventPageOpenBetsError();
    }
  }

  const handleLogOut = (...args) => {
    // actions
    actions.requestLogout();
  }

  const clearBetPlaceData = () => {
    actions.setShowBetPlacingPopover(false);
    actions.setMarket(null);
    actions.setRunner(null);
    actions.setPrice(null);
    actions.setPriceActual(null);
    actions.setDelay(null);
    actions.setStake(null);
    actions.setMarketType(null);
    actions.setMarketName(null);
    actions.setFancyPrice(null);
    actions.setIsCashOut(false);
  }

  const requestBets = () => {
    if(!user?.verifytoken) return;
    const userdata = {
      token: user?.verifytoken,
      filter: {
        username: user?.details?.username,
        deleted: false,
        result: 'ACTIVE',
        status: 'MATCHED'
      },
      sort: { placedTime: -1 },
    };

    // if(history.location.pathname.includes('virtual-event-page')){
    //   const parts = history?.location?.pathname.split('/');
    //   const eventTypeId = parts[parts.length - 1];
    //   userdata.filter['eventId'] = eventTypeId == 'v9'? '1234822733' : '1122334455';
    // }else if(history.location.pathname.includes('event-page')) {
    //   const parts = history?.location?.pathname.split('/');
    //   const lastParameter = parts[parts.length - 1];
    //   userdata.filter['eventId'] = lastParameter;
    // }else {
    //   userdata.filter['status'] = 'MATCHED';
    // }


    if(!history.location.pathname.includes('my-open-bets')) {
      Socket.emit('get-bets', userdata);
    }
  }


  useEffect(() => {
    try {
      const localToken = localStorage.getItem(`${ID_TOKEN}`);
      const localTokenExp = localStorage.getItem(`${TOKEN_EXP}`);
      const localUser = localStorage.getItem(`${USER_DATA}`);
      // If app has been F5ed  - get all app data again
      // Otherwise log user out
      if (localToken && localUser && localTokenExp) {
        // set local token in store
        // console.log("localtoken in useEffect", localToken)
        actions.getAuthSuccess(localToken);
        actions.setUserDataSuccess(JSON.parse(localUser));
        actions.setAuthDataSuccess({ payload: { exp: localTokenExp } });
      } else {
        // localStorage.setItem('page', '/default-page')
        // localStorage.setItem('page', '/')
        //actions.requestLogout()
      }

      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams?.has("txnId")) {
        const txnId = urlParams?.get("txnId");
        updatePgDepositRequest(txnId, urlParams);
      } else {
        // history.push('/default-page')
      }
    } catch (error) {
      // localStorage.setItem('page', '/default-page')
      actions.requestLogout();
    }



    actions.setBannerPopUp(true)

    setTimeout(() => {
      setAnimApp(true);
      setAnimAppOpen(true);
      setTimeout(() => {
        setAnimApp(false);
        setAnimAppClose(true);
        setAnimAppOpen(false);

      }, 20000);
    }, 6500);

    const unlisten = history.listen(({ action, location }) => {
      menuController.close();

      localStorage.setItem("page", history.location.pathname);
      localStorage.setItem("pageStack", JSON.stringify(history.entries));
    });
    // on Page Unload return to default Page
    window.onunload = function() {
      history.push("/default-page");
    };

    try{
      // set cookies if have locationSearch
      if (window.location.search) {
        let s = "=";
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        parameters.forEach((value, key, urlParam) => {
          if (key === "atag") {
            Cookies.set(`${key}`, `${value}`, { expires: 60 })
            localStorage.setItem(`${key}`, `${value}`);
          }
        });
      }

      if (Cookies.get("atag")) {
        localStorage.setItem("atag", Cookies.get("atag"));
      }

    } catch (e) {
      console.log(e);
    }
      actions.requestProfile()

    return () => {
      unlisten();
      window.onunload = null;
    };
  }, []);

  // const renderFab = () => {
  //   if (!auth) {
  //     return createPortal(
  //       <div className="whatsApp" onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
  //         <img className="whatsApp_img" src={whatsAppImg} alt="" />
  //         <div className={`circleIconPanel ${animAppOpen ? "showMessage" : animAppClose ? "hideMessage" : ""}  `}></div>
  //       </div>, modalRoot
  //     );
  //   }
  // };

  const renderFab = () => {
    if (!auth) {
      return createPortal(
        <div className="whatsApp" onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
          <img loading={"lazy"} className="whatsApp_img" src={whatsAppImg} alt="" />
          <div className={`circleIconPanel ${animAppOpen ? "showMessage" : animAppClose ? "hideMessage" : ""}  `}></div>
        </div>, modalRoot
      );
    }
  };

  useEffect(() => {
    try {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = CONFIG.favicon;
      // document.title = CONFIG.appName;

      // meta descriptions
      // const meta = document.createElement("meta");
      // meta.name = "description";
      // meta.content = CONFIG.metaDescription;
      // document.getElementsByTagName("head")[0].appendChild(meta);

      // Variable CSS
      const variableCss = document.createElement("link");
      variableCss.rel = "stylesheet";
      variableCss.type = "text/css";
      variableCss.href = CONFIG.variableCss;

      document.getElementsByTagName("head")[0].appendChild(variableCss);

      const lmtTheme = document.createElement("link");
      lmtTheme.rel = "stylesheet";
      lmtTheme.type = "text/css";
      lmtTheme.href = CONFIG.lmtTheme;
      document.getElementsByTagName("head")[0].appendChild(lmtTheme);

      // Apple-touch-icon
      const appleTouchIcon = document.createElement("link");
      appleTouchIcon.rel = "apple-touch-icon";
      appleTouchIcon.href = CONFIG.appleTouchIcon;
      document.getElementsByTagName("head")[0].appendChild(appleTouchIcon);

      //   <meta name="description" content="Lotus App" />
      //   <link rel=”mask-icon” href="%PUBLIC_URL%/logo.svg" color="#000000">
      //   <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo180.png" />
      //   <link href="%PUBLIC_URL%/manifest.json" rel="manifest" />
      const manifest = document.createElement("link");
      manifest.rel = "manifest";
      manifest.href = CONFIG.manifestUrl;
      document.getElementsByTagName("head")[0].appendChild(manifest);

      // Google Analytics
      // const js = document.createElement("script");
      // js.type = "text/javascript";
      // js.src =
      //   "https://www.googletagmanager.com/gtag/js?id=" + CONFIG.googleAnalytics;
      // // console.log('loading script')

      // window.addEventListener("load", function() {
      //   // console.log('loading done')

      //   //Code using this script here
      //   window.dataLayer = window.dataLayer || [];

      //   function gtag() {
      //     window.dataLayer.push(arguments);
      //   }

      //   gtag("js", new Date());

      //   gtag("config", CONFIG.googleAnalytics);
      // });

      //OneSignal init

      // console.log("OneSignal init with Id: ", CONFIG.oneSignalAppiD);
      // OneSignal.init({
      //   appId: CONFIG.oneSignalAppiD,
      //   safari_web_id: CONFIG.oneSignalSafariWebID,
      //   notifyButton: {
      //     enable: true
      //   }
      // }).then(() => {
      //   console.log("OneSignal init done");
      //   OneSignal.showSlidedownPrompt().then(() => {
      //     console.log("OneSignalPrompt shown");
      //   });
      // });


    } catch (e) {
      console.log(e);
    }
    // }
  }, [CONFIG, hosts]);


  useEffect(() => {
    if (!authData || !auth) return;
    else {
      const date = Date.now();
      // if (date >= authData.payload.exp * 1000 - KEEP_ALIVE_POPUP) {
        // else if (date >= authData.payload.exp * 1000 - 3580000) {
        // if (!continueSessionModalShow) {
        //   actions.setContinueSessionModalShow(false)
        // }
      // }
      const timer = setInterval(() => {
        setTimeCounter((timeCounter) => timeCounter + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, authData, timeCounter]);

  useEffect(() => {
    if (!auth || !user) return;
    if (!balance) getBalance();
    else {
      const timer = setIntervalAsync(async () => {
        await actions
          .requestBalance(user.memberCode)
          .catch((e) => console.log("balance error = ", e));
      }, GET_BALANCE_INTERVAL);
      return () => clearIntervalAsync(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, balance]);

  useEffect(() => {
    // if (!auth || !user) return
    //TODO: menu call change for each sport

    // data for menu items 
    const cricket = {'sport_id': '4'}
    const tennis = {'sport_id': '2'}
    const soccer = {'sport_id': '1'}

    // if (!menu) getMenu(cricket);
    // interval stopped for now
    // else {
    //   const timer = setIntervalAsync(async () => {
    //     await actions.requestMenu().catch((e) => console.log("menu error = ", e));
    //   }, GET_MENU_INTERVAL);
    //   return () => clearIntervalAsync(timer);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, menu]);

  useEffect(() => {
    if (!auth || !user) return;
    if (!balanceSettings) getBalanceSettings();
    const timer = setIntervalAsync(async () => {
      await actions
        .requestBalanceSettings(user.memberCode)
        .catch((e) => console.log("BalanceSettings error = ", e));
    }, GET_BALANCE_SETTINGS_INTERVAL);
    return () => clearIntervalAsync(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, balanceSettings]);
  // console.log('!! App refRecaptcha', refRecaptcha)


  useEffect(() => {
    // if (!auth || !user) return
    if (!message && auth) getMessage();
    else {
      const timer = setIntervalAsync(async () => {
        await actions
          .requestMessage(user.memberCode)
          .catch((e) => console.log("message error = ", e));
      }, GET_MESSAGE_INTERVAL);
      return () => clearIntervalAsync(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, message]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (width > 1024) {
      if (localStorage.getItem("page")) {
        setLocalPathnameNow(localStorage.getItem("page"));
      } else {
        setLocalPathnameNow("/default-page");
      }
    }
  }, [localStorage.getItem("page")]);

  useEffect(() => {
    if (auth) actions.requestOpenBets();
  }, [lastActivityDate, auth]);

  useEffect(() => {
    if (width !== elementsParams?.clientWidth) actions.setClientWidthThunk(width);

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      actions.setClientWidthThunk(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // loginPopUp
  useEffect(() => {
    if (!auth) {
      actions.setLoginNew(false);
    } else {
      actions.setLoginNew(false);
    }
  }, [auth]);

  useEffect(() => {
    appRef.current.scrollIntoView({
      block: "start", inline: "start"
    });
    // window.scrollTo({top: 10})
  }, [rulesPopUp]);

  // update pop-up
  // const [isVersionPopUpOpen, setIsVersionPopUpOpen] = useState(false);
// console.log(version)
  // useEffect(() => {
  //   let versionNow = version;

  //   if (version) {
  //     if (localStorage.getItem("versionNow") === versionNow) {
  //       return console.log("version up to date");
  //     } else if (!localStorage.getItem("versionNow")) {
  //       localStorage.setItem("versionNow", versionNow);
  //     } else if (localStorage.getItem("versionNow") != versionNow) {
  //       setIsVersionPopUpOpen(true);

  //       localStorage.setItem("versionNow", versionNow);
  //     }
  //   }
  // }, [version]);
  // end update pop-up

  const [renderFooter, setRenderFooter] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderFooter(true);
    }, 1500);
  }, []);


  //get main container height and check if it changed
  const mainContainerRef = useRef(null);
  useEffect(() => {
    actions.mainContainerHeightThunk(
      width > 1024
        ? mainContainerRef?.current?.offsetHeight +
        mainContainerRef?.current?.offsetTop
        : ""
    );
  }, [mainContainerRef ? mainContainerRef?.current?.offsetHeight : localPathnameNow]);
  //end get main container height


  const updatePgDepositRequest = async (id, urlParams) => {
    // console.log('request resent', id)
    if (id) {
      return await axios
        .get(BASE_DEV_URL + `/pg/get_pg_txn_details?transactionId=${id}`, {
          headers: {
            Authorization: localStorage.getItem(`${ID_TOKEN}`)
          }
        })
        .then((response) => {
          let depositReq = localStorage.getItem(id);

          if (!response?.data.success) {
            history.push("/default-page");
          } else if (!depositReq) {
            history.push("/default-page");
          } else {
            urlParams.delete("txnId");
            dispatch(setReq(JSON.parse(depositReq)));
            localStorage.removeItem(id);
            history.push("/depositTimer");
          }
        });
    }
  };


  const getMessage = () => {
    actions
      .requestMessage(user.memberCode)
      .catch((e) => console.log("message error = ", e));
  };

  const getBalance = () => {
    actions
      .requestBalance(user.memberCode)
      .catch((e) => console.log("balance error = ", e));
  };


  const getMenu = (data) => {
    actions.requestMenu(data).catch((e) => console.log("menu error = ", e));
  };


  const getBalanceSettings = () => {
    actions
      .requestBalanceSettings(user.memberCode)
      .catch((e) => console.log("BalanceSettings error = ", e));
  };


  const findPartOfRoute = (route, array) => {
    const res = array.filter((item) => route.indexOf(item) !== -1);
    return !!res.length;
  };


  // const renderMyMarketsFab = () =>{
  // (
  //     <MyMarketsFab
  //       click={() => {
  //         history.push('/my_markets')
  //       }}
  //     />
  //   )
  //     }

  const authRender = (Component) =>
    !auth || auth ? Component : width < 1024 ? <Login /> : <DesktopLogin />;
  const cancelBetPopover = () => {
    // console.log('cancelBetPopover  ===== ', refBetPopover.current)
    if (refBetPopover.current) {
      refBetPopover.current.dismiss();
    }
    setTimeout(() => {
      // console.log('cancelBetPopover Timeout ===== ', refBetPopover.current)
      if (refBetPopover.current) {
        refBetPopover.current.dismiss();
      } else {
        actions.setShowBetPlacingPopover(false);
      }
    }, 250);
  };
  const cancelSessionPopover = () => {
    // console.log('cancel Session Popover   ===== ', refSessionPopover.current)
    if (refSessionPopover.current) {
      refSessionPopover.current.dismiss();
    }
    setTimeout(() => {
      // console.log('cancel Session Popover Timeout  ===== ', refSessionPopover.current)
      if (refSessionPopover.current) {
        refSessionPopover.current.dismiss();
      } else {
        actions.setContinueSessionModalShow(false);
      }
    }, 250);
  };

  const closeLoginPopUp = () => {
    actions.setLoginNew(false);
  };

  const closeRuleRegulationPopup = () => {
    actions.setRuleRegulationPopUp(false)
  }


  const fullScreenPages =
    localPathnameNow === "/transactions" ||
    localPathnameNow === "/withdrawal" ||
    localPathnameNow === "/deposit" ||
    localPathnameNow === "/exchange-games" ||
    localPathnameNow === "/livecasino-lobby" ||
    localPathnameNow === "/slotsgames-lobby" ||
    localPathnameNow === "/sportsbook/1444005" ||
    localPathnameNow === "/termsOfWithdrowalPayment" ||
    history.location.pathname.includes("/live_casino_slot/") ||
    history.location.pathname.includes("/casino_live") ||
    history.location.pathname.includes("/casino_live_new") ||
    history.location.pathname.indexOf("competition") !== -1 ||
    history.location.pathname.indexOf("menu-event") !== -1 ||
    history.location.pathname.indexOf("scorecard") !== -1 ||
    localPathnameNow === "/thanksWithdrawal"
      ? true
      : false;

  const desktopWith2Container =
    localPathnameNow !== "/withdrawal" &&
    localPathnameNow !== "/deposit" &&
    localPathnameNow !== "/betting_profit_and_loss" &&
    localPathnameNow !== "/my-open-bets" &&
    localPathnameNow !== "/account_statement" &&
    localPathnameNow !== "/transfer_statement" &&
    localPathnameNow !== "/change_password" &&
    localPathnameNow !== "/transactions" &&
    localPathnameNow !== "/thanksWithdrawal" &&
    localPathnameNow !== "/termsOfWithdrowalPayment" &&
    localPathnameNow !== "/rules_regs" &&
    localPathnameNow !== "/about" &&
    localPathnameNow !== "/privacy_policy" &&
    localPathnameNow !== "/searchDesktop"&&
    localPathnameNow !== '/bonuses'
      ? // && localPathnameNow !== "/exchange-games"
      false
      : true;

  const B2C_layout =
    localPathnameNow === "/transactions" ||
    localPathnameNow === "/withdrawal" ||
    localPathnameNow === "/deposit" ||
    localPathnameNow == "/termsOfWithdrowalPayment" ||
    localPathnameNow === "/thanksWithdrawal" ||
    localPathnameNow === '/bonuses'
      ? true
      : false;


  // Temporary added for development !!!

  const [openErrorPopUp, setOpenErrorPopUp] = useState(false);

  window.onerror = async (message, file, line, col, error) => {
    try {
      console.log("CRASH DETECTED " + file + ":" + line + ":" + col)
      console.log("CRASH DETECTED", message);
      console.log("error", error);
      // setOpenErrorPopUp(true);
      const userIP = await fetch("https://api.ipify.org/?format=json").then(res => res.json());
      if (message !== "ResizeObserver loop limit exceeded") {
        Sentry.captureMessage(`IMPORTANT ${window.location.host} ${localStorage.getItem("page")} ${localStorage.getItem("versionNow")} userIP:${userIP?.ip} ` + error);
        // window.location.reload();
        // localStorage.removeItem("pageStack")
        // localStorage.removeItem("page")
      }

    } catch (e) {
      console.log(e);
    }

  };


  return (
    <IonApp
      ref={appRef}
      onScroll={() => {
        setScrollPosition(appRef?.current?.scrollTop);
        actions.scrollPositionThunk(appRef?.current?.scrollTop);
      }}
      className={`${continueSessionModalShow ? "" : "hideSesPopover"} 
      ${
        loginNew ||
        isConfirmBetPopUpOpen ||
        continueSessionModalShow ||
        rulesPopUp ||
        bonusPopUp ||
        isPopUpOpenDispatch
          ? "disable-events"
          : ""
      }`}
    >
      <BetPopoverContext.Provider value={cancelBetPopover}>
        <IonReactMemoryRouter history={history}>
          {width < 1024 ? (
            <>
              <IonRouterOutlet
                id="LeftSide"
                mode="md"
                className="rou222ter-outlet"
              >
                <Route
                  exact
                  path="/search"
                  render={() => authRender(<Search />)}
                />
                <Route
                  exact
                  path="/exchange-games"
                  render={() => authRender(<ExchangeGamesMobile />)}
                />
                <Route
                  exact
                  path="/betting_profit_and_loss"
                  render={() => authRender(<BettingProfitAndLosePage />)}
                />
                <Route
                  exact
                  path="/transfer_statement"
                  render={() => authRender(<TransferStatementPage />)}
                />
                <Route
                  exact
                  path="/account_statement"
                  render={() => authRender(<AccountStatementPage />)}
                />
                <Route
                  exact
                  path="/transaction_popup"
                  render={() => authRender(<TransactionPopup />)}
                />
                <Route
                  exact
                  path="/betting_profit_and_loss_details"
                  render={() => authRender(<BettingProfitAndLossDetailsPage />)}
                />
                <Route
                  exact
                  path="/account_statement_details"
                  render={() => authRender(<AccountStatementDetailsPage />)}
                />
                <Route
                  exact
                  path="/settings"
                  render={() => authRender(<Settings />)}
                />
                <Route
                  exact
                  path="/time_settings"
                  render={() => authRender(<TimeSettings />)}
                />
                <Route
                  exact
                  path="/change_password"
                  render={() => authRender(<ChangePassword />)}
                />
                <Route
                  exact
                  path="/competition/:eventTypeId/:competitionId"
                  render={() => authRender(<Competition />)}
                />
                <Route
                  exact
                  path="/rules_page/:eventTypeId/:competitionId"
                  render={() => authRender(<RulesPage />)}
                />
                <Route
                  exact
                  path="/menu-event/:eventTypeId"
                  render={() => authRender(<MenuEvent />)}
                />
                <Route
                  exact
                  path="/event-page/:eventTypeId/:eventId"
                  render={(props) => authRender(<EventPage key={props.match.params.eventId} />)}
                />
                <Route
                  exact
                  path="/virtual-event-page/:eventTypeId"
                  render={(props) => authRender(<VirtualEventPage key={props.match.params.eventTypeId} />)}
                />
                <Route
                  exact
                  path="/exchange-game-page/:eventTypeId/:eventId"
                  render={() => authRender(<ExchangeGamePage />)}
                />
                <Route
                  exact
                  path="/scorecard/:eventTypeId/:eventId"
                  render={() => authRender(<Scorecard />)}
                />
                <Route
                  exact
                  path="/casino_games"
                  render={() => authRender(<CasinoGames />)}
                />
                <Route
                  exact
                  path={[
                    "/casino_live/:gameId",
                    "/casino_live/:gameId/:tableId"
                  ]}
                  render={() =>
                    authRender(
                      <CasinoLive localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path={"/casino_live_new/:gameName"}
                  render={() =>
                    authRender(
                      <CasinoLiveNew localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path={[
                    "/live_casino_slot/:casinoId",
                    "/live_casino_slot/:casinoId/:typeId"
                  ]}
                  render={() =>
                    authRender(
                      <LiveCasinoAndSlotPage localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path="/sportsbook/:casinoId"
                  render={() =>
                    authRender(
                      auth ? (
                        <Sportbook localPathnameNow={localPathnameNow} />
                      ) : (
                        <SportsbookNotAuth
                          localPathnameNow={localPathnameNow}
                        />
                      )
                    )
                  }
                />
                <Route
                  exact
                  path="/my_markets"
                  render={() => authRender(<MyMarkets />)}
                />
                <Route
                  exact
                  path="/my-open-bets"
                  render={() => authRender(<MyOpenBets />)}
                />
                <Route
                  exact
                  path="/privacy_policy"
                  render={() => authRender(<PrivacyPolicy />)}
                />
                <Route
                  exact
                  path="/responsible_gambling"
                  render={() => authRender(<ResponseGambling />)}
                />
                <Route
                  exact
                  path="/exclusion_policy"
                  render={() => authRender(<ExclusionPolicy />)}
                />
                <Route
                  exact
                  path="/bonus_statement"
                  render={() => authRender(<BonusStatementPage />)}
                />
                <Route
                  exact
                  path="/kyc"
                  render={() => authRender(<KycPage />)}
                />
                <Route
                  exact
                  path="/rules_regs"
                  render={() => authRender(<RulesRegs />)}
                />
                <Route
                  exact
                  path="/deposit"
                  render={() => authRender(<Deposit />)}
                />
                <Route
                  exact
                  path="/transactions"
                  render={() => authRender(<Transactions />)}
                />
                <Route
                  exact
                  path="/detailedDepositTransaction"
                  render={() => authRender(<DetailedDepositTransaction />)}
                />
                <Route
                  exact
                  path="/withdrawal"
                  render={() => authRender(<Withdrawal />)}
                />
                <Route
                  exact
                  path="/depositTimer"
                  render={() => authRender(<DepositTimer />)}
                />
                <Route
                  exact
                  path="/depositTimerQr"
                  render={() => authRender(<DepositTimerQr />)}
                />
                <Route
                  exact
                  path="/thanksWithdrawal"
                  render={() => authRender(<ThanksWithdrawal />)}
                />
                <Route
                  exact
                  path="/termsOfWithdrowalPayment"
                  render={() => authRender(<TermsOfWithdrowalPayment />)}
                />
                <Route
                  exact
                  path="/balanceInformation"
                  render={() => authRender(<BalanceInformation />)}
                />
                <Route
                  exact
                  path="/betSettingsDesktop"
                  render={() => authRender(<BetSettingsDesktop />)}
                />
                <Route
                  exact
                  path="/favouritesPage"
                  render={() => authRender(<FavouritesPage />)}
                />
                <Route
                  exact
                  path="/betSlipDesktop"
                  render={() => authRender(<BetSlipDesktop />)}
                />
                <Route
                  exact
                  path={["/livecasino-lobby", "/livecasino-lobby/:gameType"]}
                  render={() =>
                    authRender(
                      <LiveCasinoLobby localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path="/slotsgames-lobby"
                  render={() =>
                    authRender(
                      <SlotsGamesLobby localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path="/bonus_statement"
                  render={() => authRender(<BonusStatementPage />)}
                />
                <Route
                  exact
                  path="/bonuses"
                  render={() => authRender(<Bonuses />)}
                />
                <Route
                  exact
                  path="/profile"
                  render={() => authRender(<Profile />)}
                />
                <Route
                  exact
                  path="/default-page"
                  render={() =>
                    authRender(
                      <DefaultPage localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Redirect to="/default-page" />
              </IonRouterOutlet>
              <LeftSideMenu />
              <RightSideMenu />

            </>
          ) : (
            <>
               {openErrorPopUp && <ErrorPopUp/>}
               {/* {console.log('History bolo: ', history.location)} */}
              {((!auth || auth) && (!history.location.pathname.includes('casino_live'))) && (
                <IonHeader>
                  <ToolbarDesktop key="search" search />
                </IonHeader>
              )}
              <div
                className={`main_container ${
                  localStorage.getItem("page") === "/exchange-games"
                    ? "ExchangeGamesLobby"
                    : ""
                }`}
                onClick={() => {
                  actions.setSearchValues("");
                }}
                style={fullScreenPages ? { maxHeight: "450px" } : {}}
              >
                <div className="main_container-left">
                  <LeftSideMenuDesktop />
                </div>
                <div
                  className={`main_container-center ${
                    desktopWith2Container ? 'main_container-center__big' : ''
                  }  ${B2C_layout ? 'relative' : '11111'} `}
                  ref={mainContainerRef}
                >
                  <Switch>
                    <Route
                      exact
                      path="/searchDesktop"
                      render={() => authRender(<SearchDesktop />)}
                    />
                    <Route
                      exact
                      path={["/livecasino-lobby", "/livecasino-lobby/:gameType"]}
                      render={() =>
                        authRender(
                          <LiveCasinoLobby
                            localPathnameNow={localPathnameNow}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/slotsgames-lobby"
                      render={() =>
                        authRender(
                          <SlotsGamesLobby
                            localPathnameNow={localPathnameNow}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/exchange-games"
                      render={() =>
                        authRender(
                          <ExchangeGames localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/betting_profit_and_loss"
                      render={() => authRender(<BettingProfitAndLosePage />)}
                    />
                    <Route
                      exact
                      path="/transfer_statement"
                      render={() => authRender(<TransferStatementPage />)}
                    />
                    <Route
                      exact
                      path="/account_statement"
                      render={() => authRender(<AccountStatementPage />)}
                    />
                    <Route
                      exact
                      path="/transaction_popup"
                      render={() => authRender(<TransactionPopup />)}
                    />
                    <Route
                      exact
                      path="/betting_profit_and_loss_details"
                      render={() =>
                        authRender(<BettingProfitAndLossDetailsPage />)
                      }
                    />
                    <Route
                      exact
                      path="/account_statement_details"
                      render={() => authRender(<AccountStatementDetailsPage />)}
                    />
                    <Route
                      exact
                      path="/settings"
                      render={() => authRender(<Settings />)}
                    />
                    <Route
                      exact
                      path="/time_settings"
                      render={() => authRender(<TimeSettings />)}
                    />
                    <Route
                      exact
                      path="/change_password"
                      render={() => authRender(<ChangePassword />)}
                    />
                    <Route
                      exact
                      path="/competition/:eventTypeId/:competitionId"
                      render={() => authRender(<Competition />)}
                    />
                    <Route
                      exact
                      path="/rules_page/:eventTypeId/:competitionId"
                      render={() => authRender(<RulesPage />)}
                    />
                    <Route
                      exact
                      path="/menu-event/:eventTypeId"
                      render={() => authRender(<MenuEvent />)}
                    />
                    <Route
                      exact
                      path="/event-page/:eventTypeId/:eventId"
                      render={(props) => authRender(<EventPage key={props.match.params.eventId} />)}
                    />
                    <Route
                      exact
                      path="/virtual-event-page/:eventTypeId"
                      render={(props) => authRender(<VirtualEventPage key={props.match.params.eventTypeId} />)}
                    />
                    <Route
                      exact
                      path="/exchange-game-page/:eventTypeId/:eventId"
                      render={() => authRender(<ExchangeGamePage />)}
                    />
                    <Route
                      exact
                      path="/scorecard/:eventTypeId/:eventId"
                      render={() => authRender(<Scorecard />)}
                    />
                    <Route
                      exact
                      path="/casino_games"
                      render={() => authRender(<CasinoGames />)}
                    />
                    <Route
                      exact
                      path={[
                        "/casino_live/:gameId",
                        "/casino_live/:gameId/:tableId"
                      ]}
                      render={() =>
                        authRender(
                          <CasinoLive localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path={"/casino_live_new/:gameName"}
                      render={() =>
                        authRender(
                          <CasinoLiveNew localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path={[
                        "/live_casino_slot/:casinoId",
                        "/live_casino_slot/:casinoId/:typeId"
                      ]}
                      render={() =>
                        authRender(
                          <LiveCasinoAndSlotPage localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/sportsbook/:casinoId"
                      render={() =>
                        authRender(
                          auth ? (
                            <Sportbook localPathnameNow={localPathnameNow} />
                          ) : (
                            <SportsbookNotAuth
                              localPathnameNow={localPathnameNow}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/my_markets"
                      render={() => authRender(<MyMarkets />)}
                    />
                    <Route
                      exact
                      path="/my-open-bets"
                      render={() => authRender(<MyOpenBets />)}
                    />
                    <Route
                      exact
                      path="/responsible_gambling"
                      render={() => authRender(<ResponseGambling />)}
                    />
                    <Route
                      exact
                      path="/exclusion_policy"
                      render={() => authRender(<ExclusionPolicy />)}
                    />
                    <Route
                      exact
                      path="/kyc"
                      render={() => authRender(<KycPage />)}
                    />
                    <Route
                      exact
                      path="/rules_regs"
                      render={() => authRender(<RulesRegs />)}
                    />
                    <Route
                      exact
                      path="/deposit"
                      render={() => authRender(<Deposit />)}
                    />
                    <Route
                      exact
                      path="/transactions"
                      render={() => authRender(<Transactions />)}
                    />
                    <Route
                      exact
                      path="/sports-page"
                      render={() => authRender(<SportsPage />)}
                    />
                    <Route
                      exact
                      path="/racing-sports-page"
                      render={() => authRender(<RacingPage />)}
                    />
                    <Route
                      exact
                      path="/detailedDepositTransaction"
                      render={() => authRender(<DetailedDepositTransaction />)}
                    />
                    <Route
                      exact
                      path="/withdrawal"
                      render={() => authRender(<Withdrawal />)}
                    />
                    <Route
                      exact
                      path="/depositTimer"
                      render={() => authRender(<DepositTimer />)}
                    />
                    <Route
                      exact
                      path="/depositTimerQr"
                      render={() => authRender(<DepositTimerQr />)}
                    />
                    <Route
                      exact
                      path="/thanksWithdrawal"
                      render={() => authRender(<ThanksWithdrawal />)}
                    />
                    <Route
                      exact
                      path="/termsOfWithdrowalPayment"
                      render={() => authRender(<TermsOfWithdrowalPayment />)}
                    />
                    <Route
                      exact
                      path="/balanceInformation"
                      render={() => authRender(<BalanceInformation />)}
                    />
                    <Route
                      exact
                      path="/betSettingsDesktop"
                      render={() => authRender(<BetSettingsDesktop />)}
                    />
                    <Route
                      exact
                      path="/favouritesPage"
                      render={() => authRender(<FavouritesPage />)}
                    />
                    <Route
                      exact
                      path="/betSlipDesktop"
                      render={() => authRender(<BetSlipDesktop />)}
                    />
                    <Route
                      exact
                      path="/bonuses"
                      render={() => authRender(<Bonuses />)}
                    />
                    <Route
                      exact
                      path="/default-page"
                      render={() =>
                        authRender(
                          <DefaultPage localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Redirect to="/default-page" />
                  </Switch>
                  {desktopWith2Container && (
                    <div className="balance-info">
                      <BalanceInformation />
                    </div>
                  )}
                </div>
                {(auth || !auth) &&
                  !desktopWith2Container &&
                  localPathnameNow &&
                  !localPathnameNow.includes("sportsbook") &&
                  !localPathnameNow.includes("casino_live") &&
                  !localPathnameNow.includes("exchange-games") && (
                    <div className="main_container-rigth">
                      <div
                        className="main_container__betslip-desktop_wrapper"
                        style={{ top: `-${balanceInformationHeight}px` }}
                      >
                        <BalanceInformation />
                        {auth && <BetSettingsDesktop />}
                        {/*<LiveMatchVideo localPathnameNow={localPathnameNow} />*/}
                        {width > 1024 && <LastResults isGame={false} />}
                        <BetSlipDesktop />

                      </div>
                    </div>
                  )}
              </div>
              {isPopUpOpenDispatch && (
                <div
                  className="editStakesPopUp_wrapperr"
                  style={{ top: `${scrollPosition}px`, zIndex: "3" }}
                >
                  <div
                    style={scrollPosition < 170
                      ? { top: `${170 - scrollPosition}px`, zIndex: "3" }
                      : {}}
                    className="editStakesPopUp">
                    <EditStakesPopUp open={isPopUpOpenDispatch} />

                  </div>
                </div>
              )}
              {(auth || !auth) &&
                localPathnameNow !== "/sportsbook/1444005" &&
                localPathnameNow !== "/exchange-games" &&
                localPathnameNow !== "/livecasino-lobby" &&
                localPathnameNow !== "/slotsgames-lobby" &&
                localPathnameNow !== "/transactions" &&
                localPathnameNow !== "/deposit" &&
                localPathnameNow !== "/withdrawal" &&
                localPathnameNow !== "/termsOfWithdrowalPayment" &&
                localPathnameNow !== "/thanksWithdrawal" &&
                !history.location.pathname.includes("/competition") &&
                !history.location.pathname.includes("/menu-event") &&
                !history.location.pathname.includes("/scorecard") &&
                !history.location.pathname.includes("/live_casino_slot/") &&
                !history.location.pathname.includes("/casino_live") && (
                  renderFooter && <FooterDesktop />
                )}
            </>
          )}
          <LoginPopUp
            open={loginNew}
            onDidDismiss={closeLoginPopUp}
            scrollPosition={scrollPosition}
          />

          <RuleRegulationPopup
            open={ruleRegulationPopUp}
            onDidDismiss={closeRuleRegulationPopup}
            scrollPosition={scrollPosition}
          />

          <ComingSoonPopup />
          

          {/* <UpdatePopUp isOpen={isVersionPopUpOpen} /> */}
          {bonusPopUp && <BonusPopUp scrollPosition={scrollPosition} />}
          <SessionPopover
            cancel={cancelSessionPopover}
            refSessionPopover={refSessionPopover}
            open={continueSessionModalShow}
            scrollPosition={scrollPosition}
          />
          {width < 1024 && showBetPlacingPopover ? (
            <BetPlacingPopover
              cancel={cancelBetPopover}
              refBetPopover={refBetPopover}
              open={showBetPlacingPopover}
            />
          ) : (
            <ConfirmBetPopUp
              cancel={cancelBetPopover}
              refBetPopover={refBetPopover}
              open={showBetPlacingPopover}
            />
          )}
          {width < 1024 && rulesPopUp && <RulesPopUp />}

          {/* {<ReduxToastr
            timeOut={loginNew ? 0 : 35000}
            closeOnToastrClick={false}
            position="top-center"
            transitionIn="bounceInDown"
            transitionOut="bounceOutUp"
          />} */}
          {/* {isPopUpOpenDispatch &&
           <div className='editStakesPopUp'>
            <div className='editStakesPopUp_wrapperr'>
              <EditStakesPopUp/>
            </div>
            } */}
        </IonReactMemoryRouter>
        {/* {bonusPopUp && <BonusPopUp></BonusPopUp>} */}
        {/* {renderMyMarketsFab()} */}
        {/* <MyMarketsFab
        click={() => {
          history.push('/my_markets')
        }}
      />  */}
        {renderFooter && renderFab()}
        {/* {!auth &&
        <div className='whatsApp' style={{ top: ` calc(${scrollPosition}px + 90%`}} onClick={() => window.open(`https://wa.me/${CONFIG.mobiNumber}`)}>
        <img className='whatsApp_img' src={whatsAppImg} alt="" />
        <div className={`whatsApp_box ${animApp ? 'active' : 'close'}`}>
          <span className='whatsApp_box_text'>Play and Win. Get a new ID instantly over WhatsApp</span> </div>
      </div>
      } */}
      </BetPopoverContext.Provider>
    </IonApp>
  );
};
export default withIonLifeCycle(connectApp()(Sentry.withProfiler(App)));
